/* eslint-disable camelcase */
import styles from './configs.module.scss';
import { GoogleIcon } from '../../../../static/community/icons/google';
import { FacebookIcon } from '../../../../static/community/icons/facebook';
import { AppleIcon } from '@buzzfeed/react-components';
import firstStepImg from '../../../../static/community/SuperheroFreak.png';
import secondStepImg from '../../../../static/community/HomepageHero.png';
import thirdStepImg from '../../../../static/community/500kViews.png';
import ogImage from '../../../../static/community/ogImg.png';
import Link from '../../common/Link';
import { bfUrl } from '../../../../constants';

export const redirectUrl = `${bfUrl}/community/about`;

export const googleLoginLink = `${bfUrl}/auth/csrf?provider=google&redirect=${redirectUrl}`;
export const facebookLoginLink = `${bfUrl}/auth/csrf?provider=facebook&redirect=${redirectUrl}`;
export const appleLoginLink = `${bfUrl}/auth/csrf?provider=apple&redirect=${redirectUrl}`;
export const emailLoginLink = `${bfUrl}/auth/signin?redirect=${redirectUrl}`;

export const communityUrl = 'https://community.buzzfeed.com/';
export const quizzesLink =
  'https://www.buzzfeed.com/annakopsky/everything-you-need-to-know-to-make-a-buzzfeed-personality';
export const postsLink =
  'https://www.buzzfeed.com/annakopsky/the-ultimate-guide-to-making-a-buzzfeed-community-post';
export const communityLeaderboard =
  'https://www.buzzfeed.com/community/leaderboard';
export const communityRules = 'https://www.buzzfeed.com/community/rules';
const communityAgreement = 'https://www.buzzfeed.com/about/useragreement';
export const writerChallengeLink =
  'https://www.buzzfeed.com/tag/community_challenge_info';

export const LOGIN_BUTTONS_CONFIG = [
  {
    name: 'Sign up with Google',
    url: googleLoginLink,
    btnStyle: 'loginGoogleBtn',
    icon: <GoogleIcon />,
    trackingInfo: {
      item_name: 'signup_google',
    },
  },
  {
    name: 'Sign up with Facebook',
    url: facebookLoginLink,
    btnStyle: 'loginFbBtn',
    icon: <FacebookIcon />,
    trackingInfo: {
      item_name: 'signup_facebook',
    },
  },
  {
    name: 'Sign up with Apple',
    url: appleLoginLink,
    btnStyle: 'loginAppleBtn',
    icon: <AppleIcon fill="#fff" />,
    trackingInfo: {
      item_name: 'signup_apple',
    },
  },
  {
    name: 'Sign up with Email',
    url: emailLoginLink,
    btnStyle: 'loginEmailBtn',
    trackingInfo: {
      item_name: 'signup_email',
    },
  },
];
export const STEPS_CONFIG = [
  {
    stepNumber: 1,
    title: '',
    desc: (
      <>
        Make sure to review our{' '}
        <Link link="#community-guidelines" name="guidelines" target="" /> before
        getting started!
      </>
    ),
    img: firstStepImg,
    url: emailLoginLink,
    btnName: 'Create an account',
    trackingInfo: {
      unit_name: 'main',
      item_name: 'account_signup',
    },
    trackingInfoExternalLink: {},
  },
  {
    stepNumber: 2,
    title: (
      <>
        Create your very own <Link link={quizzesLink} name="quizzes" /> and{' '}
        <Link link={postsLink} name="posts" />!
      </>
    ),
    desc: 'Sign in into our CMS to be able to create your first post.',
    img: secondStepImg,
    url: emailLoginLink,
    btnName: 'Make your first post',
    trackingInfo: {
      unit_name: 'main',
      item_name: 'create_post',
      target_content_type: 'auth',
      target_content_id: 'sign_in',
    },
    trackingInfoExternalLink: {
      item_name: 'create_post',
      target_content_url: communityUrl,
      link_id: 'feed',
    },
  },
  {
    stepNumber: 3,
    title: 'Hit publish to make your post go live',
    desc: 'Every post submitted to Community will be reviewed by an editor.',
    img: thirdStepImg,
    url: communityLeaderboard,
    btnName: 'View posts leaderboard',
    trackingInfo: {
      unit_name: 'main',
      item_name: 'view_leaderboard',
      target_content_type: 'url',
      target_content_id: communityLeaderboard,
    },
    trackingInfoExternalLink: {},
  },
];

const GUIDELINES_CONFIG = [
  'Self-Promotion',
  'Politics',
  'You To Make Money For Your Business',
  'You To Use Others’ Content Without Permission (That Applies To Plagiarism, Too!)',
  'Trying Your Hand At News Reporting',
  'Waging A War Against Other Community Members',
  'PR Professionals To Plug Clients',
  'Spam',
];

export const STEPS_DESC = `Please note that the review process can take a few days or
longer based on factors including, but not limited to, current
trends and topics. If your post or quiz is selected to get
featured across BuzzFeed's network, our editors may make
edits to reflect the BuzzFeed style and tone. You'll also
get an email letting you know that it's been chosen. If
your post is not selected to be featured, don't feel
discouraged! Our editors will still see your future creations
and continue to consider them.`;

const CREATE_QUIZ_LINKS = [
  {
    name: 'trivia',
    link: 'https://www.buzzfeed.com/annakopsky/the-official-guide-to-making-a-buzzfeed-trivia-quiz',
  },
  {
    name: 'personality',
    link: quizzesLink,
  },
  {
    name: 'checklist',
    link: 'https://www.buzzfeed.com/annakopsky/checklist-quiz-guide-2019',
  },
  {
    name: 'poll',
    link: 'https://www.buzzfeed.com/annakopsky/how-to-make-a-poll-quiz-community',
  },
  {
    name: 'tap-on-image',
    link: 'https://www.buzzfeed.com/annakopsky/how-to-make-a-tap-on-image-quiz',
  },
];

export const CREATE_POST_DESC = `There are few things more classic on the internet than a good ol’
BuzzFeed list or post, made by creative people like you! Gush
about your favorite fandom, try something new and exciting and
write about it, create a list of music or book recommendations —
pretty much whatever you can think of. Best of all, maybe even
find your people right here on the Community!`;

export const CreateQuizDesc = () => {
  return (
    <>
      Making your own BuzzFeed Community quiz is your opportunity to create
      content about your favorite topics, including celebs, shows, music, games,
      TikTok trends (really, anything!) that you wish you saw more of on
      BuzzFeed. You can be the trendsetter and maybe even find or build your own
      community along the way!
      <br />
      <br />
      There are so many types of quizzes you can make on the Community,
      including{' '}
      {CREATE_QUIZ_LINKS.map((i) => (
        <Link key={i.name} link={i.link} name={`${i.name}, `} />
      ))}
      and more.
    </>
  );
};

export const WRITERS_CHALLENGES_DESC =
  'We have various fun opportunities throughout the year for writers to earn rewards and money for their contributions. To learn more about current and upcoming chances for you to create and earn, check out the Challenges page.';

export const GuidelinesDesc = () => {
  return (
    <>
      Before you sign up to create your first post, please review our{' '}
      <Link link={communityRules} name="Rules and Guidelines" />. BuzzFeed
      Community is subject to BuzzFeed’s general User Agreement, which you can
      check out <Link link={communityAgreement} name="here" />
      .
      <br />
      <br /> BuzzFeed’s Community is not a place for…
      <ol className={styles.orderedList}>
        {GUIDELINES_CONFIG.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ol>
    </>
  );
};

export const COMMUNITY_HEAD_INFO = {
  communityTitles: {
    'community-featured': 'BuzzFeed Community',
    'community-leaderboard': 'BuzzFeed Community Leaderboard',
    'community-guidelines': 'BuzzFeed Community Guidelines',
    'community-about': 'Join BuzzFeed Community - Make a Quiz!',
  },
  communityDesc:
    'The best place to make quizzes or posts about your favorite TV show, fandom, or just your latest obsession!',
  communityImage: ogImage,
};

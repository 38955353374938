/* eslint-disable camelcase */
/* eslint-disable react/no-unknown-property */
/* eslint-disable @next/next/no-sync-scripts */
import { APSTAG_SNIPPET } from '@buzzfeed/adlib/bindings/react/constants/snippets.js';
import { jsProfilerAsString } from '@buzzfeed/performance';
import Head from 'next/head';
import {
  abeagleHost,
  gtm_enabled,
  gtm_id,
} from '../constants';
import { COMMUNITY_HEAD_INFO } from '../components/Community/constants/configs/index';

const GOOGLE_TAG_MANAGER = `
(function(w,d,s,l,i){
  w[l] = w[l]||[];
  w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l!='dataLayer'?'&l='+l:'';
  j.async = true;
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
  f.parentNode.insertBefore(j,f);
})(window, document, 'script', 'dataLayer', '${gtm_id}');`;

function removeTrailingSlash(str) {
  return str.replace(/\/+$/, '');
}

function CustomHead({ pageConfig = {}, inlineCss, page = 'homepage', userGeo }) {
  const { meta, canonicalUrl } = pageConfig;
  const { description, title } = meta || {};

  const { communityTitles, communityDesc, communityImage } = COMMUNITY_HEAD_INFO;
  const gtmEnabled = gtm_enabled && userGeo !== 'US';
  const isHomepage = page === 'homepage';
  const isArcade = page === 'arcade';
  const isCommunity = page.startsWith('community');
  const pageTitle = isCommunity ? communityTitles[page] : title;
  const pageDesc = isCommunity ? communityDesc : description;

  return (
    <>
      <Head>
        {/* charset / viewport */}
        <meta key="charset" charSet="utf-8" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge"></meta>
        <meta
          key="viewport"
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1"
        />
        {/* canonical */}
        <link
          rel="canonical"
          href={isCommunity ? canonicalUrl : removeTrailingSlash(canonicalUrl)}
        />

        {/* this has been on our hp for a long time */}
        <meta httpEquiv="refresh" content="3600" />

        {/* preconnect */}
        <link rel="preconnect" crossOrigin="true" href="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" />
        <link rel="preconnect" crossOrigin="true" href={abeagleHost} />

        {/* apstag */}
        <link rel="dns-prefetch" href="https://c.amazon-adsystem.com" />
        <link
          rel="preconnect"
          href="https://c.amazon-adsystem.com"
          crossOrigin=""
        />
        <script
          key="apstag"
          dangerouslySetInnerHTML={{ __html: APSTAG_SNIPPET }}
        />
        {/* Google Tag Manager */}
        {gtmEnabled && !isCommunity && (
          <>
            <link
              rel="preconnect"
              crossOrigin="true"
              href="https://www.googletagmanager.com"
            />
            <link rel="dns-prefetch" href="https://www.googletagmanager.com" />
          </>
        )}
        {/* Connatix script */}
        <link rel="dns-prefetch" href="https://cd.connatix.com/connatix.player.js" />
        <link
          rel="preconnect"
          href="https://cd.connatix.com/connatix.player.js"
          crossOrigin=""
        />
        {/* Inline JS */}
        <script
          key="js-profiling"
          dangerouslySetInnerHTML={{ __html: jsProfilerAsString() }}
        />
        {/* Google Tag Manager (SEO) */}
        {gtmEnabled && !isCommunity && (
          <script
            key="google-tag-manager"
            dangerouslySetInnerHTML={{ __html: GOOGLE_TAG_MANAGER }}
          />
        )}

        {/* preload */}
        <link
          key="preload"
          rel="preload"
          href="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
          as="script"
        />
        <link
          key="manifest"
          rel="manifest"
          crossOrigin="use-credentials"
          href="/manifest.json"
        />

        {/* Linked scripts `<script src=""></script>` */}
        <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" />

        {/* Page Metadata */}
        <title>{pageTitle}</title>
        <meta key="title" name="title" content={pageTitle} />
        <meta name="description" content={pageDesc} />
        <meta name="copyright" content={meta.copyright} />
        <meta name="referrer" content="unsafe-url" />

        {/* fb tags */}
        <meta
          property="og:image"
          content={isCommunity ? communityImage : meta.fb.image}
        />
        <meta property="og:site_name" content="BuzzFeed" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={pageDesc} />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:title" content={pageTitle} />
        <meta property="fb:app_id" content={meta.fb.appId} />
        <meta property="fb:smart_publish:robots" content="noauto" />
        <meta property="fb:pages" content={meta.fb.pages} />

        {/* twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@buzzfeed" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDesc} />
        <meta name="twitter:url" content={canonicalUrl} />
        <meta property="twitter:user_id" content={meta.twitter.userId} />
        <meta
          name="twitter:image"
          content={isCommunity ? communityImage : meta.twitter.image}
        />

        {/* apple */}
        <meta name="theme-color" content="#ee3322" />
        {!isCommunity && (
          <>
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="apple-mobile-web-app-title" content={pageTitle} />
            <link
              rel="apple-touch-startup-image"
              href={meta.apple.startupImage}
            />
            <link rel="apple-touch-icon" href={meta.apple.touchIcon} />
            {/* apple icons */}
            <link
              rel="apple-touch-icon"
              sizes="76x76"
              href={meta.apple.touchIconSizes['76x76']}
            />
            <link
              rel="apple-touch-icon"
              sizes="120x120"
              href={meta.apple.touchIconSizes['120x120']}
            />
            <link
              rel="apple-touch-icon"
              sizes="152x152"
              href={meta.apple.touchIconSizes['152x152']}
            />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href={meta.apple.touchIconSizes['180x180']}
            />
            <link rel="alternate" href={meta.android.alternate} />
          </>
        )}
        {/* android  */}
        <link
          rel="shortcut icon"
          sizes="196x196"
          href={meta.android.shortcutIcon}
        />
        <link
          rel="shortcut icon"
          crossOrigin=""
          href={meta.android.shortcutIconIco}
          type="image/x-icon"
        />
        {/* hreflang */}
        {isHomepage && (
          <>
            <link
              rel="alternate"
              href="https://www.buzzfeed.com/au"
              hrefLang="en-au"
            />
            <link
              rel="alternate"
              href="https://www.buzzfeed.com/ca"
              hrefLang="en-ca"
            />
            <link
              rel="alternate"
              href="https://www.buzzfeed.com/in"
              hrefLang="en-in"
            />
            <link
              rel="alternate"
              href="https://www.buzzfeed.com/jp"
              hrefLang="ja"
            />
            <link
              rel="alternate"
              href="https://www.buzzfeed.com/mx"
              hrefLang="es"
            />
            <link
              rel="alternate"
              href="https://www.buzzfeed.com/uk"
              hrefLang="en-gb"
            />
          </>
        )}
        <link rel="alternate" href="https://www.buzzfeed.com" hrefLang="en" />
        <link
          rel="alternate"
          href="https://www.buzzfeed.com"
          hrefLang="x-default"
        />

        {/* google site verification */}
        <meta
          name="verify-v1"
          content="qct7joXhI16inETfLQC5KTGifz+G24JAIGte8I8Asvk="
        />
        <meta
          name="google-site-verification"
          content="K6vTphiVLWnOeDHcoE-UdWFLP36XHzogi6LFEMjT0go"
        />
        <meta
          name="google-site-verification"
          content="4ernshgeEVQ_0cDiRER0NIiz8CYk9UvBgs1K3D4Y4Tk"
        />
        <meta
          name="google-site-verification"
          content="JEd21RvFY8cNjSsR1l4Xgw67nk103KlfbEnF60whHbk"
        />
        <meta
          name="google-site-verification"
          content="FeKSvH7ewgJ0J8bz18ndqwQgXc6mnqb664h48gmWxQ8"
        />
        <meta
          name="google-site-verification"
          content="dCFiSMkIuhexqSHAtYslzYguwMxIJ3-D7hty5Jl-AGg"
        />
        <meta
          name="google-site-verification"
          content="U_49afMkAENCqPXTKUxBAYU-aTdGxBY3TUUFMPZRF1s"
        />
        {!isCommunity && (
          <>
            <meta
              name="verify-v1"
              content="bdsDohJaS/FrxCJJOTN4fHDjgMKuWpGdMAKe1LwgrRo="
            />
            <meta
              name="google-site-verification"
              content="6cl9SMQVEzSOiQzTnrHT6xfEarc4SJOJrI_wQcO5oKo"
            />
            <meta name="bitly-verification" content="ff4043a0a01b" />
            <meta
              name="msvalidate.01"
              content="2B0DFF08E2C0AFEF56B45C60CC005CDF"
            />
          </>
        )}
        {inlineCss && (
          <style dangerouslySetInnerHTML={{ __html: inlineCss }}></style>
        )}
        {isArcade && (
          <link rel="stylesheet" href="https://use.typekit.net/wtq1mvd.css"/>
        )}
      </Head>
    </>
  );
}

// export default withTranslation('common')(CustomHead);
export default CustomHead;
